<template>
  <v-footer app dark class="blue-grey darken-4">
    <v-progress-linear v-if="computedLoading" indeterminate>
    </v-progress-linear>
    <v-alert
      v-if="computedErpOffline"
      dense
      icon="mdi-wifi-off"
      type="error">
      O ERP (Protheus) está offline. As ações que dependam do ERP estão indisponíveis.
    </v-alert>
    <v-spacer></v-spacer>
    <span class="text-caption ml-auto">
      Powered by <a href="https://userfunction.com.br" target="_blank" class="white--text font-weight-bold">{{this.$appConfig.poweredBy ? this.$appConfig.poweredBy : 'User Function' }}</a> <span>v1.13.3</span>.
    </span>
  </v-footer>
</template>

<script>
import { createNamespacedHelpers, mapActions, mapState } from 'vuex'
const { mapActions: registersActions } = createNamespacedHelpers("registers");

export default {
  name: 'Footer',
  data() {
    return {
      erpOffline: false,
      loading: false,
      interval: null
    }
  },
  computed: {
    ...mapState(["companySelected"]),
    computedErpOffline() {
      return this.erpOffline
    },
    computedLoading() {
      return this.loading
    }
  },
  methods: {
    ...registersActions([
      "getApi"
    ]),
  },
  async created() {
    const timeout = this.companySelected?.configs?.apiRest[this.companySelected?.M0_CODFIL]?.timeout || 12000
    this.loading = true
    try {
      const result = await this.getApi({timeout, url: '/protheus/', options: {noToast: true}})
      if (result.status === 500) {
        this.erpOffline = true
      } else {
        this.erpOffline = false
      }
    } catch (error) {
      this.erpOffline = true
    }
    this.loading = false
    this.interval = setInterval(async () => {
      try {
        const result = await this.getApi({timeout, url: '/protheus/', options: {noToast: true}})
        if (result.status === 500) {
          this.erpOffline = true
        } else {
          this.erpOffline = false
        }
      } catch (error) {
        this.erpOffline = true
      }
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style>

</style>